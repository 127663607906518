

// 左侧菜单列表

// SPA业务
// eslint-disable-next-line no-unused-vars
const menuSpa = [
    {
      "name":"首页轮播",
      "path":"/spa/banner",
      "sub":[],
      "param": {
        "type": 'ad',
        "w": 1380,
        "h": 1100
      }
    },
    {
      "name":"首页焦点",
      "path":"/spa/banner",
      "sub":[],
      "param": {
        "type": 'news',
        "w": 660,
        "h": 1020
      }
    },
    // {
    //   "name":"资料查看",
    //   "sub":[
    //     {
    //       "name":"资料查看",
    //       "path":"/spa/material",
    //       "param": {}
    //     },
    //   ]
    // },
    {
      "name":"标签管理",
      "path":"/spa/tagManager",
      "sub":[]
    },
    {
      "name":"营销助理",
      "sub":[
        {
          "name":"开业活动",
          "path":"/spa/content/list",
          "param": {
            "id": 1,
            "w": 1380,
            "h": 956
          }
        },
        {
          "name":"全年活动",
          "path":"/spa/content/tab",
          "param": {
            "category":[
              {
                  "name":"节日性",
                  "id":2,
                  "type":-1,
                  "param": {
                    "w": 1040,
                    "h": 720
                  }
              },
              {
                  "name":"季节性",
                  "id":3,
                  "type":-1,
                  "param": {
                    "w": 1040,
                    "h": 720
                  }
              },
              {
                  "name":"新品新项目",
                  "id":4,
                  "type":-1,
                  "param": {
                    "w": 1040,
                    "h": 720
                  }
              }
            ]
          }
        },
        {
          "name":"皮肤分析仪诊断",
          "path":"/spa/content/list",
          "param": {
            "id":5,
            "w": 1380,
            "h": 956
          }
        },
        {
          "name":"广告画报",
          "path":"/spa/content/list",
          "param": {
            "id":6,
            "w": 660,
            "h": 1020
          }
        }
      ]
    },
    {
      "name":"品牌展示",
      "sub":[
        {
          "name":"维娜",
          "path":"/spa/content/fixed/list",
          "param": {
            "list":[
              {
                "name":"公司介绍",
                "id":7,
                "type":1,
                "param": {
                  "w": 670,
                  "h": 864
                }
              },
              {
                "name":"维娜荣誉",
                "id":8,
                "type":1,
                "param": {
                  "w": 670,
                  "h": 664
                }
              },
              {
                "name":"公司沿革",
                "id":9,
                "type":1,
                "param": {
                  "w": 670,
                  "h": 664
                }
              },
              {
                "name":"维娜设施",
                "id":10,
                "type":1,
                "param": {
                  "w": 670,
                  "h": 864
                }
              },
              {
                "name":"事业领域",
                "id":11,
                "type":1,
                "param": {
                  "w": 432,
                  "h": 504
                }
              },
              {
                "name":"CEO问候语",
                "id":12,
                "type":1,
                "param": {
                  "w": 432,
                  "h": 504
                }
              },
              {
                "name":"维娜社刊",
                "id":13,
                "type":1,
                "param": {
                  "w": 432,
                  "h": 504
                }
              }
            ]
          }
        },
        {
          "name":"SPA",
          "path":"/spa/content/fixed/list",
          "param": {
            "list":[
              {
                "name":"品牌介绍",
                "id":14,
                "type":1,
                "param": {
                  "w": 670,
                  "h": 980
                }
              },
              {
                "name":"品牌形象",
                "id":15,
                "type":1,
                "param": {
                  "w": 670,
                  "h": 788
                }
              },
              {
                "name":"品牌优势",
                "id":16,
                "type":1,
                "param": {
                  "w": 670,
                  "h": 788
                }
              },
              {
                "name":"全国优秀spa案例",
                "id":17,
                "type":-1,
                "param": {
                  "w": 670,
                  "h": 980
                }
              },
              {
                "name":"教育体系",
                "id":20,
                "type":-1,
                "param": {
                  "w": 670,
                  "h": 504
                }
              },
              {
                "name":"spa品牌荣誉",
                "id":21,
                "type":1,
                "param": {
                  "w": 670,
                  "h": 504
                }
              },
              {
                "name":"开启梦想",
                "id":22,
                "type":-1,
                "param": {
                  "w": 1380,
                  "h": 220
                }
              }
            ]
          }
        }
      ]
    },
    // {
    //   "name":"品牌封面",
    //   "path":"/spa/menuCover",
    //   "sub":[],
    //   "param": {
    //     "brand": "2-4"
    //   }
    // },
    {
      "name":"焦点资讯",
      "path":"/spa/content/list",
      "sub":[],
      "param": {
        "id":23,
        "w": 1380,
        "h": 840
      }
    },
    {
      "name":"教育培训",
      "path":"/spa/content/tab",
      "sub":[],
      "param": {
        "category":[
          {
            "name":"培训体系",
            "id":24,
            "type":-1,
            "param": {
              "w": 1040,
              "h": 720
            }
          },
          {
            "name":"科普",
            "id":25,
            "type":-1,
            "param": {
              "w": 1040,
              "h": 720
            }
          },
          {
            "name":"产品详解",
            "id":26,
            "type":-1,
            "param": {
              "w": 1040,
              "h": 720
            }
          },
          {
            "name":"技术实操",
            "id":27,
            "type":-1,
            "param": {
              "w": 1040,
              "h": 720
            }
          },
          {
            "name":"项目运营",
            "id":28,
            "type":-1,
            "param": {
              "w": 1040,
              "h": 720
            }
          },
          {
            "name":"店务管理",
            "id":29,
            "type":-1,
            "param": {
              "w": 1040,
              "h": 720
            }
          }
        ]
      }
    }
  ]
  
  // 加盟店铺
  const menuStore = [
    {
      "name":"加盟店铺",
      "path":"/Franchisee",
      "roleTags": ['FRANCHISE'],
      "sub":[]
    },
    {
      "name":"SPA审核",
      "path":"/spaReview",
      "roleTags": ['FRANCHISE'],
      "sub":[]
    },
    {
      "name":"客户列表",
      "path":"/customerList",
      "roleTags": ['FRANCHISE'],
      "sub":[]
    }
  ]

  // 客户管理
  const menuCustomer = [
    {
      "name":"市场人员",
      "path":"/agentMarketer",
      "roleTags": ['MARKETER'],
      "sub":[]
    },
    {
      "name":"市场总监",
      "path":"/agentLeader",
      "roleTags": ['LEADER'],
      "sub":[]
    },
    {
      "name":"签约公司",
      "path":"/agentCompany",
      "roleTags": ['COMPANY'],
      "sub":[]
    },
  ]
  
  // 分析
  const menuSales = [
    {
      "name":"销售概况",
      "path":"/salesHome",
      "roleTags": [],
      "sub":[]
    },
    {
      "name":"销售排名",
      "path":"/rankingList",
      "roleTags": [],
      "sub":[]
    },
    {
      "name":"年销售统计",
      "path":"/salesYear",
      "roleTags": [],
      "sub":[]
    },
    {
      "name":"月销售统计",
      "path":"/salesMonth",
      "roleTags": [],
      "sub":[]
    },
    {
      "name":"组织架构",
      "path":"/organization",
      "roleTags": [],
      "sub":[]
    },
    {
      "name":"店铺列表",
      "path":"/salesStoreList",
      "roleTags": [],
      "sub":[]
    },
    {
      "name":"店铺业绩",
      "path":"/salesStore",
      "roleTags": [],
      "sub":[]
    },
    {
      "name":"室长业绩",
      "path":"/salesMarketer",
      "roleTags": [],
      "sub":[]
    },
    {
      "name":"产品销量",
      "path":"/salesProductList",
      "roleTags": [],
      "sub":[]
    },
  ]
  
  // 基础管理
  // const menuBase = [
  //   // {
  //   //   "name":"店铺列表",
  //   //   "path":"/storeList",
  //   //   "roleTags": ['PRODUCT'],
  //   //   "sub":[]
  //   // }
  // ]
  
  // 设置
  const menuSetting = [
    {
      "name":"角色管理",
      "path":"/role",
      "roleTags": ['ROLE_R','ROLE_W'],
      "sub":[]
    },
    {
      "name":"员工管理",
      "path":"/staff",
      "roleTags": ['STAFF_VIEW', 'STAFF'],
      "sub":[]
    },
    {
      "name":"合作公司",
      "path":"/erpCompany",
      "roleTags": ['COMPANY'],
      "sub":[]
    },
    {
      "name":"组织架构",
      "path":"/organizationalManagement",
      "roleTags": ['ORGNIZATION'],
      "sub":[]
    },
    {
      "name":"产品管理",
      "path":"/productList",
      "roleTags": ['PRODUCT', 'PRODUCT_W'],
      "sub":[]
    },
    {
      "name":"专家建议",
      "path":"/expertAdvice",
      "roleTags": ['PRODUCT', 'PRODUCT_W'],
      "sub":[]
    },
    {
      "name":"批量操作",
      "path":"/batchCenter",
      "roleTags": [],
      "sub":[]
    },
    {
      "name":"服务设置",
      "path":"/serviceManager",
      "roleTags": ['SYSTEM'], 
      "sub":[]
    },
    {
      "name":"公告管理",
      "path":"/noticesManager",
      "roleTags": ['NOTICE'], 
      "sub":[]
    },
    {
      "name":"协议管理",
      "path":"/routine",
      "roleTags": ['PAGE'],
      "sub":[]
    }
  ]
  
  // 促销 9
  // eslint-disable-next-line no-unused-vars
  const menuPromotion = [
    {
      "name":"首页轮播",
      "path":"/crouselChart",
      "roleTags": ['BANNER'],
      "sub":[]
    },
    {
      "name":"促销横幅",
      "path":"/homeExtension",
      "roleTags": ['BANNER'],
      "sub":[]
    },
    {
      "name":"产品促销",
      "path":"/productPromotion",
      "roleTags": ['PROMOTION_R', 'PROMOTION_RW', 'PROMOTION_EXAM', 'PROMOTION_CONF'],
      "sub":[]
    },
    {
      "name":"维娜促销公文",
      "path":"/weinaPromotion",
      "roleTags": ['REWARD_WN_R', 'REWARD_WN_RW', 'REWARD_WN_EXAM', 'REWARD_WN_CONF'],
      "sub":[]
    },
    {
      "name":"SPA促销公文",
      "path":"/spaPromotion",
      "roleTags": ['REWARD_SP_R', 'REWARD_SP_RW', 'REWARD_SP_EXAM', 'REWARD_SP_CONF'],
      "sub":[]
    },
    {
      "name":"抽奖管理",
      "path":"/raffleList",
      "roleTags": ['RAFFLE_R', 'RAFFLE_W', 'RAFFLE_ORDER'],
      "sub":[]
    },
    // {
    //   "name":"5万礼包",
    //   "path":"/giftPackage",
    //   "roleTags": ['REWARD_GP_R', 'REWARD_GP_RW', 'REWARD_GP_EXAM', 'REWARD_GP_CONF'],
    //   "sub":[]
    // }
  ]
  
  
  // eslint-disable-next-line no-unused-vars
  const menuTest = [
    {
      "name":"图片分析",
      "path":"/imageAnalyze",
      "roleTags": [],
      "sub":[]
    }
  ]
  
  // 左侧菜单列表
  const menuList = [
    {
      "name": "首页",
      "path":"/homePage",
      "icon": "/image/homeView/icon-home.png",
      "roleTags": ["HOME"],
      "sub": []
    },
    {
      "name": "店铺管理",
      "icon": "/image/homeView/icon-store.png",
      "roleTags": [],
      "sub": menuStore
    },
    {
      "name": "客户管理",
      "icon": "/image/homeView/icon-group.png",
      "roleTags": [],
      "sub": menuCustomer
    },
    {
      "name": "促销管理",
      "icon": "/image/homeView/icon-event.png",
      "roleTags": [],
      "sub": menuPromotion
    },
    {
      "name": "订单管理",
      "path": "/orderManagement",
      "icon": "/image/homeView/icon-order.png",
      "roleTags": ['ORDER_R', 'ORDER_SUBMIT', 'ORDER_REVIEW', 'ORDER_EXAM', 'ORDER_CONF', 'ORDER_CLOSE'],
      "sub": []
    },
    // {
    //   "name": "SPA业务",
    //   "icon": "/image/homeView/icon-spa.png",
    //   "roleTags": ['SPA_DATA'],
    //   "sub": menuSpa
    // },
    {
      "name": "数据分析",
      "icon": "/image/homeView/icon-data.png",
      "roleTags": ['DATA_ANALYSIS'],
      "sub": menuSales
    },
    // {
    //   "name": "基础管理",
    //   "icon": "/image/homeView/icon-setting.png",
    //   "roleTags": [],
    //   "sub": menuBase
    // },
    {
      "name": "系统管理",
      "icon": "/image/homeView/icon-system.png",
      "roleTags": [],
      "sub": menuSetting
    },
    // {
    //   "name": "实验室",
    //   "icon": "/image/homeView/icon-lab.png",
    //   "roleTags": ['ADMIN', 'SUDOER', 'LAB'],
    //   "sub": menuTest
    // }
  ]
  
  // 根据当前权限，当前使用的 menu 菜单列表
  let currentMenuList = [];
  function updateCurrentMenuList(list) {
    currentMenuList = list
  }

  /** 
 * 获取 menu 数据
 * @param {menu索引} menuIndex
 * 
 * @returns Object 数据
*/
const getMenuData = (menuIndex) => {
    const arr = menuIndex.split('-');
    let data = currentMenuList[arr[0]];
    arr.shift();
    for( const i in arr) {
      data = data.sub[arr[i]];
    }
    return data;
  }
  
  /** 
   * 获取 menu 一级数据
   * @param {menu索引} menuIndex
   * 
   * @returns Object 数据
  */
  const getMenuFirstData = (menuIndex) => {
    const arr = menuIndex.split('-');
    let data = currentMenuList[arr[0]];
    return data;
  }
  
  /** 
   * 获取 menu path
   * @param {menu索引} menuIndex
   * 
   * @returns [] path
  */
  const getMenuPath = (menuIndex) => {
    const arr = menuIndex.split('-');
    var path = [];
    let data = currentMenuList[arr[0]];
    if (data) {
      path.push(data.key);
      arr.shift();
      for( const i in arr) {
        data = data.sub[arr[i]];
        path.push(data?.key ?? '');
      }
    }
    return path;
  }
  

/**
 * 同一给菜单列表 添加key
 * 
 * 例：
 * [
 *  {
 *    key: 1
 *    sub: [
 *      {
 *         key: 1-1,
 *         sub: [
 *            {
 *              key: 1-1-1,
 *              sub: []
 *            }
 *         ]
 *      }
 *    ]
 *  }
 * ]
 * */ 
function updateMenuListKey(pre, list) {
    list.forEach((item, index) => {
      item.key = pre + (index + 1).toString();
      let sub = item?.sub ?? []
      if (sub.length > 0) {
        item.sub = updateMenuListKey(item.key + '-', sub)
      }
    })
    return list
}
updateMenuListKey('', menuList)

// 更新参数 menuIndex 
function updateMenuListIndex(pre, list) {
  list.forEach((item, index) => {
    item.menuIndex = pre + index.toString();
    let sub = item?.sub ?? []
    if (sub.length > 0) {
      item.sub = updateMenuListIndex(item.menuIndex + '-', sub)
    }
  })
  return list
}

/**
 * 返回当前列表(菜单)的第一个页面
 *  
 * @param list：当前菜单列表
 * @param key：菜单的key
 * 
 * @return:
 *  @param path: 路径，若是菜单上的path，会携带上 menuIndex (用于定位菜单位置)
 *  @param keys: 菜单上的层级key
 * */ 
function getCurrentFirstPathAndKey(list, keys = []) {
  if (list.length != 0) {
    let first = list?.[0]
    let sub = first?.sub ?? []
    let path = first?.path ?? ''
    let menuIndex = first?.menuIndex ?? ''
    if (path != '') {
      if (menuIndex != '') {
        path += ('?menuIndex=' + menuIndex)
      }
      keys.push(first.key)
      return {
        path: path,
        keys: keys
      }
    } else if (sub.length > 0){
      keys.push(first.key)
      return getCurrentFirstPathAndKey(sub, keys)
    }
  }
  return {
    path: '/404',
    keys: []
  }
}

/**
 * 根据路径获取菜单参数，菜单key
 * 
 * @param path：要匹配的路径
 * 
 * @return:
 *  @param path: 路径，若是菜单上的path，会携带上 menuIndex (用于定位菜单位置)
*/
function getPathAndKey(path) {
  let list = getMenuAllItem()
  if (list.length != 0) {
    for( const i in list) {
      let item = list[i];
      let menuIndex = item?.menuIndex ?? ''
      let itemPath = item?.path 
      if (itemPath == path) {
        if (menuIndex != '') {
          itemPath += ('?menuIndex=' + menuIndex)
        }
        return {
          path: itemPath
        }
      }
    }
  }
  return {
    path: path
  }
}

// 将整个 menu 的所有 path，展开到一个数组中
function getMenuAllItem(list = currentMenuList) {
  let newList = [];
  list.forEach((item) => {
    newList.push(item);
    if (item?.sub?.length > 0) {
      newList = newList.concat(getMenuAllItem(item?.sub));
    }
  });
  return newList;
}

const menu = {
  menuList,
  updateCurrentMenuList,  // 更新当前的 menu list
  updateMenuListIndex,    // 更新 menu list index
  getCurrentFirstPathAndKey,    // 返回列表中的第一条路径
  getPathAndKey,          // 根据路径获取菜单参数，菜单key
  getMenuData,
  getMenuPath,
  getMenuFirstData,
};

export default menu;
