// var OSS = require('ali-oss');
import OSS from "ali-oss";
import { login } from "../store/pinia";

export function client(bucket = 'weina-oms') {
  var client = new OSS({
    region: "oss-cn-shanghai",
    accessKeyId: login().oss.accessKeyId,
    accessKeySecret: login().oss.accessKeySecret,
    stsToken: login().oss.securityToken,
    bucket: bucket,
    timeout: 60 * 5 * 1000, // 上传图片超时时间
  }); //后端提供数据
  return client;
}

export function lbsClient(bucket = 'weina-lbs') {
  var client = new OSS({
    region: "oss-cn-shanghai",
    accessKeyId: login().oss.accessKeyId,
    accessKeySecret: login().oss.accessKeySecret,
    stsToken: login().oss.securityToken,
    bucket: bucket,
    timeout: 60 * 5 * 1000, // 上传图片超时时间
  }); //后端提供数据
  return client;
}

// 获取签名url
export function signatureUrl(client, url) {
  return client.signatureUrl(url);
}

export const headers = {
  // 指定该Object被下载时的网页缓存行为。
  // "Cache-Control": "no-cache",
  // 指定该Object被下载时的名称。
  // "Content-Disposition": "example.txt",
  // 指定该Object被下载时的内容编码格式。
  // "Content-Encoding": "utf-8",
  // 指定过期时间，单位为毫秒。
  // Expires: "1000",
  // 指定Object的存储类型。
  // "x-oss-storage-class": "Standard",
  // 指定Object的访问权限。
  "x-oss-object-acl": "public-read",
  // 指定Object标签，可同时设置多个标签。
  // "x-oss-tagging": "Tag1=1&Tag2=2",
  // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
  // "x-oss-forbid-overwrite": "true",
};
/**
 * 生成随机uuid
 */
export const getFileNameUUID = () => {
  return (
    new Date().getTime() +
    Math.floor(Math.random() * 10 + 1).toString() +
    Math.floor(Math.random() * 10 + 1).toString()
  );
};

// 1 促销管理-首页轮播
export const oss_banner = `/img/banner/cover_banner_top_`;
// 2 促销管理-促销横幅
export const oss_basics = `/img/banner/cover_banner_reward_`;
// 3 促销管理-维娜促销
export const oss_weina = `/img/promotion/weina/cover_weina_`;
// 4 促销管理-SPA促销
export const oss_spa = `/img/promotion/spa/cover_spa_`;
// 5 促销管理-5w大礼包
export const oss_5w = `/img/promotion/gift/cover_gift_`;
// 6 订单管理-审核
export const oss_toExamine = `/img/order/img_order_`;
// 7 个人信息-头像
export const oss_userInfo = `/img/user/img_user_`;
// 8 富文本图片
export const oss_richText = `/img/rich/img_rich_`;
// 9 促销管理-产品促销
export const oss_product = `/img/promotion/product/cover_product_`;
// 10 公告管理-公告
export const oss_notices = `/img/notices/img_`;
// 11 抽奖
export const oss_raffle = `/img/raffle/cover_product_`;


// 61 SPA业务-内容
export const oss_spa_content = `/img/spa/content/cover_`;
// 62 SPA业务-富文本
export const oss_spa_rich = `/img/spa/rich/img_`;
// 63 SPA业务-banner
export const oss_spa_banner = `/img/spa/banner/img_`;
// 64 SPA业务-菜单封面
export const oss_spa_menu = `/img/spa/menu/img_`;