<!-- eslint-disable no-unused-vars -->
<script setup>
import { getCurrentInstance, reactive, ref } from "vue";
import { ElLoading } from "element-plus";

const emit = defineEmits(["change"]);
const { proxy } = getCurrentInstance();
const props = defineProps({
  size: {          // 当 size 有 0 时，不限制大小
    type: Array,
  },
  disabled: {
    type: Boolean,
    default: false
  },
  type: {
    type: Number,
  },
  status: {
    type: String,
    default: "default"
  },
  path: {
    type: String,
    default: ""
  },
  bucket: {
    type: String,
    default: "oms"
  }
});

// 自定义上传
const refUpload = ref(null)
const fileAfter = ref(".jpg")
const fileList = ref();
const result = reactive({
  name: "",
  url: "",
});
const uploadURL = (file) => {
  if (proxy.$piniaJs.login().getLoginEndTime === 301) {
    // Oss凭证失效过期
    proxy.$tool.msgWarning("凭证已过期，请重新登录");
    proxy.$router.push("/login");
    return;
  }
  let url;
  if (props.path?.length > 0) {
    url = props.path;
  } else {
    switch (props.type) {
      case 1:
        url = proxy.$oss.oss_banner;
        break;
      case 2:
        url = proxy.$oss.oss_basics;
        break;
      case 3:
        url = proxy.$oss.oss_weina;
        break;
      case 4:
        url = proxy.$oss.oss_spa;
        break;
      case 5:
        url = proxy.$oss.oss_5w;
        break;
      case 6:
        url = proxy.$oss.oss_toExamine;
        break;
      case 6.1:
        url = "/img/order/secretary/img_";
        break;
      case 6.2:
        url = "/img/order/review/img_";
        break;
      case 6.3:
        url = "/img/order/finance/img_";
        break;
      case 6.4:
        url = "/img/order/headquarters/img_";
        break;
      case 6.5:
        url = "/img/order/change/img_";
        break;
      case 7:
        url = proxy.$oss.oss_userInfo;
        break;
      case 8:
        url = proxy.$oss.oss_richText;
        break;
      case 9:
        url = proxy.$oss.oss_product;
        break;
      case 11:
        url = proxy.$oss.oss_raffle;
        break;

      case 61:
        url = proxy.$oss.oss_spa_content;
        break;
      case 63:
        url = proxy.$oss.oss_spa_banner;
        break;
      case 64:
        url = proxy.$oss.oss_spa_menu;
        break;
    }
  }
  
  const loading = ElLoading.service({
    lock: true,
    text: "正在上传",
    background: "rgba(0, 0, 0, 0.7)",
  });
  try {
    let client
    if (props.bucket == 'oms') {
      client = proxy.$oss.client();
    } else if (props.bucket == 'lbs') {
      client = proxy.$oss.lbsClient();
    }

    if (!client) {
      fileList.value = [];
      proxy.$tool.msgError("缺失client, 上传失败！");
      loading.close();
      return;
    }
    client.put(
      url + proxy.$oss.getFileNameUUID() + fileAfter.value,
      file.file,
      proxy.$oss.headers
    )
    .then((res) => {
      result.name = res.name;
      result.url = res.url;
      emit("change", result.name);
      fileList.value = [];
      loading.close();
    })
    .catch((err) => {
      fileList.value = [];
      proxy.$tool.msgError("接口错误，上传失败！");
      loading.close();
    });
  } catch (err) {
    fileList.value = [];
    proxy.$tool.msgError("异常，上传失败！");
    loading.close();
  }
};

const uploadImgChange = async (uploadFile) => {
  let imgType = uploadFile.raw.name.split(".")
  if (imgType[imgType.length - 1] !== 'png' && imgType[imgType.length - 1] !== 'jpg' && imgType[imgType.length - 1] !== 'jpeg') {
    proxy.$tool.msgWarning('请上传jpg、png格式的图片!')
    setTimeout(() => {
      refUpload.value.clearFiles()
    }, 0);
    return false;
  }
  // 限制图片大小
  if (uploadFile.raw.size / 1024 / 1024 > 2) {
    proxy.$tool.msgWarning('请上传2Mb以内的图片!')
    setTimeout(() => {
      refUpload.value.clearFiles()
    }, 0);
    return false;
  }
  let pxResult = await uploadBefareSize(uploadFile.raw, props.size)
  if (!pxResult) {
    proxy.$tool.msgWarning(`图片尺寸不符，请传尺寸 ${props.size[0]}px * ${props.size[1]}px 图片`)
    refUpload.value.clearFiles()
  } else {
    refUpload.value.submit()
  }
}

// 尺寸是否符合
const uploadBefareSize = (files, size) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(files)
    reader.onload = function (theFile) {
      const image = new Image()
      image.src = theFile.target?.result
      image.onload = (res) => {
        let fir = size?.[0] ?? 0
        let sec = size?.[0] ?? 0
        if (fir == 0 || sec == 0) { // 当 size 有 0 时，不限制大小
          resolve(true)
        } else if (res.target.width !== size[0] || res.target.height !== size[1]) {
          resolve(false)
        } else {
          resolve(true)
        }
      }
    }
  })
}
const uploadFilesRequest = (file) => {
  // 限制附件大小
  let imgType = file.name.split(".")
  if (file.size / 1024 / 1024 > 5) {
    proxy.$tool.msgError('请上传5Mb以内的图片!')
    return false
  }
  fileAfter.value = "." + imgType[imgType.length - 1]
}

</script>

<template>
  <div>
    <el-upload :disabled="disabled" v-if="props.status === 'files'" :limit="1" :before-upload="uploadFilesRequest"
      v-model:file-list="fileList" :http-request="uploadURL"
      accept=".jpg,.jpeg,.png,.doc,.docx,.pdf,.xlsx,.pptx,.ppt,.xls,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
      <slot></slot>
    </el-upload>
    <el-upload ref="refUpload" v-else :limit="1" :auto-upload="false" @change="uploadImgChange"
      v-model:file-list="fileList" :http-request="uploadURL" accept=".png,.jpg,.jpeg,">
      <slot></slot>
    </el-upload>
  </div>
</template>
