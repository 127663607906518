import { get, post, put, del } from "./collect";
import { Urls } from "./apiSpaUrl";

/**  ===========================  SPA 接口  ===========================  **/

// SPA 标签 - 列表
export const tag_list = () => {
  return get(Urls.tag);
};
// SPA 标签 - 添加
export const tag_add = (params) => {
  return post(Urls.tag, params);
};
// SPA 标签 - 修改
export const tag_edit = (id, params) => {
  return put( Urls.tag_id(id), params);
};
// SPA 标签 - 删除
export const tag_delete = (id, params) => {
  return del(Urls.tag_id(id), params);
};
// SPA 标签 - 激活
export const tag_active = (id) => {
  return post(Urls.tag_id(id));
};

// 内容 - 列表
export const content_list = (params) => {
  return get(Urls.content, params);
};
// 内容 - 添加
export const content_add = (params) => {
  return post(Urls.content, params);
};
// 内容 - 修改
export const content_edit = (id, params) => {
  return put(Urls.content_id(id), params);
};
// 内容 - 查询
export const content_detail = (id) => {
  return get(Urls.content_id(id));
};
// 内容 - 删除
export const content_delete = (id) => {
  return del(Urls.content_id(id));
};
// 内容 - 搜索
export const content_search = (params) => {
  return get(Urls.content_search, params);
};

// banner - 列表
export const banner_list = (params) => {
  return get(Urls.banner, params);
};
// banner - 添加
export const banner_add = (params) => {
  return post(Urls.banner, params);
};
// banner - 修改
export const banner_edit = (id, params) => {
  return put(Urls.banner_id(id), params);
};
// banner - 查询
export const banner_detail = (id) => {
  return get(Urls.banner_id(id));
};
// banner - 删除
export const banner_delete = (id) => {
  return del(Urls.banner_id(id));
};

// 菜单 - 列表
export const menu_list = () => {
  return get(Urls.menu);
};
// 菜单 - 修改
export const menu_edit = (id, params) => {
  return put(Urls.menu_id(id), params);
};