<script setup>
import { ref, onMounted, getCurrentInstance, onUnmounted, watch } from "vue";

const { proxy } = getCurrentInstance();
// const emit = defineEmits(["updateHeader"]);

// 导航路由及标题
const menuList = ref([]);
const selectedKeys = ref();
const openKeys = ref();
const sider = ref('');
// 选择边栏
const handleClick = (e) => {
  proxy.$piniaJs.layoutSider().saveKeyPath(e.keyPath);
};
// 设置初始值
const setLayout = () => {
  selectedKeys.value = proxy.$piniaJs.layoutSider().keyPath;
  openKeys.value = proxy.$piniaJs.layoutSider().keyPath;
};
// 获取高度
const oldScreenHeight = ref(68 + 50);
const screenHeight = ref();
const getWindowInfo = () => {
  screenHeight.value = window.innerHeight;
};

defineExpose({
  setLayout,
});

// 更新左侧导航栏定位
const beforeEachGuard = proxy.$router.beforeEach((to, from, next) => {
  let menuIndex = to.query.menuIndex;
  if (menuIndex == undefined) {
    next();
    return;
  }
  let keyPath = proxy.$config.menu.getMenuPath(menuIndex);
  if (keyPath && keyPath?.length > 0) {
    selectedKeys.value = keyPath;
    openKeys.value = keyPath;
  }

  let data = proxy.$config.menu.getMenuFirstData(menuIndex);
  if (data) {
    sider.value = data.name;
  }
  next();
  /* 必须调用 `next` */
})

// 加载侦听
const loadWatch = () => {
  watch(
    () => proxy.$route.query.menuIndex,
    () => {
      let menuIndex = proxy.$route.query.menuIndex;
      if (menuIndex == undefined) {
        return;
      }
      const data = proxy.$config.menu.getMenuFirstData(menuIndex);
      if (data) {
        sider.value = data.name;
      }
    }, { immediate: true }
  )
}

onMounted(() => {
  window.addEventListener("resize", getWindowInfo);
  screenHeight.value = window.innerHeight;
  menuList.value = proxy.$piniaJs.menuList().list
  // 更新 menu 中的 当前 menu list，用于名称更新等功能
  proxy.$config.menu.updateCurrentMenuList(menuList.value);
  loadWatch();
});

onUnmounted(()=>{
  window.removeEventListener("resize", getWindowInfo);
  proxy.$router.beforeEach(beforeEachGuard); // 这将取消监听
})
</script>

<template>
  <div class="sider">
    <div class="sider_title">{{ sider }}</div>
    <div
      class="sider_main"
      :style="{ height: screenHeight - oldScreenHeight + 'px' }"
    >
      <a-menu
        id="dddddd"
        v-model:openKeys="openKeys"
        v-model:selectedKeys="selectedKeys"
        style="width: 100%; background-color: #1f212d"
        mode="inline"
        @click="handleClick"
        theme="dark"
      >

       <!-- 一级目录 -->
       <template v-for="(fir) in menuList">
          <template v-if="fir.sub.length == 0">
            <a-menu-item :key="fir.key">
              <template #icon>
                <img
                  class="layout_main_msg cur"
                  :src="fir.icon"
                />
              </template>
              <RouterLink :to="{ path: fir.path, query: {menuIndex: fir.menuIndex} }">{{ fir.name }}</RouterLink>
            </a-menu-item>
          </template>
          <template v-else>
            <a-sub-menu :key="fir.key">
              <template #icon>
                <img
                  class="layout_main_msg cur"
                  :src="fir.icon"
                />
              </template>
              <template #title>{{ fir.name }}</template>
              
              <!-- 二级目录 -->
              <template v-for="(sec) in fir.sub">
                <template v-if="sec.sub.length == 0">
                  <a-menu-item :key="sec.key">
                    <router-link :to="{ path: sec.path, query: {menuIndex: sec.menuIndex}}"> {{ sec.name }} </router-link>
                  </a-menu-item>
                </template>
                <template v-else>
                  <a-sub-menu :key="sec.key">
                    <template #title> {{ sec.name }} </template>

                    <!-- 三级目录 -->
                    <a-menu-item v-for="(third) in sec.sub" :key="third.key">
                      <router-link :to="{ path: third.path, query: {menuIndex: third.menuIndex}}"> {{ third.name }} </router-link>
                    </a-menu-item>
                  </a-sub-menu>
                </template>
              </template>
            </a-sub-menu>
          </template>
        </template>
      </a-menu>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.layout_main_msg {
  transform: translateY(-1px);
  width: 16px; 
  height: 16px;
}
.sider {
  text-align: left;
  &_main {
    overflow-y: auto;
  }
  &_title {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    margin-left: 20px;
    line-height: 68px;
    height: 68px;
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #53616f;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  border-radius: 0;
  background: #263748;
}
</style>
