import { defineStore } from "pinia";

// 边栏路由
export const layoutSider = defineStore("layoutSider", {
  state: () => {
    return {
      keyPath: ["1"],
    };
  },
  actions: {
    saveKeyPath(array) {
      this.keyPath = array;
    },
  },
  // 数据持久化存储
  persist: true,
});

// 当前登录账号的菜单缓存
export const menuList = defineStore("menuList", {
  state: () => {
    return {
      list: []
    }
  },
  actions: {
    updateList(list) {
      this.list = list
    }
  },
  // 数据持久化存储
  persist: true,
})

// 富文本
export const richText = defineStore("richText", {
  state: () => {
    return {
      init: false,
    };
  },
  actions: {
    saveInit(element) {
      this.init = element;
    },
  },
});

// 登录
export const login = defineStore("login", {
  state: () => {
    return {
      jwt: {},
      user: {},
      oss: {},
      other: {
        fcJwt: '',
      },
      loginEndTime: 0,
      OssEndTime: 0,
    };
  },
  getters: {
    // token有效期
    getLoginEndTime: (state) => {
      let time = new Date().getTime();
      if (time >= state.loginEndTime) {
        return 301;
      }
      return 200;
    },
    // oss Token到期时间
    getOssEndTime: (state) => {
      let time = new Date().getTime();
      if (time >= state.OssEndTime) {
        return 301;
      }
      return 200;
    },
  },
  actions: {
    clearData() {
      this.OssEndTime = 0;
      this.loginEndTime = 0;
      this.jwt = {};
      this.user = {};
      this.oss = {};
      this.other.fcJwt = '';
    },
    updateOssEndTime(element) {
      this.OssEndTime = element;
    },
    updateLoginEndTime(element) {
      this.loginEndTime = element;
    },
    updateJwt(element) {
      this.jwt = element;
    },
    updateUser(element) {
      this.user = element;
    },
    updateOss(element) {
      this.oss = element;
    },
    updateFCJwt(element) {
      this.other.fcJwt = element;
    },
  },
  // 数据持久化存储
  persist: true,
  // strategies: [
  //   { storage: sessionStorage, paths: ["firstName", "lastName"] },
  //   { storage: localStorage, paths: ["accessToken"] },
  // ],
});

// 记录
export const record = defineStore("record", {
  state: () => {
    return {
      record: {}, // 正常记录(一级页面)
      recordSec: {}, // 正常记录(二级页面)
      routerRecord: [], // 路由记录
      weinaRecord: [], // 维娜记录
      productRecord: {
        // 加盟促销记录
        type: [],
        a: [],
        b1: [],
        b2: [],
        c: [],
      },
      giftPackRecord: {
        purchase: [], // 五万礼包，自购产品记录
        present: [],  // 五万礼包 选购产品记录
      },
      promotion: [],  // 促销赠送、起案赠送，自选产品
      orderCustomer: {},  // 促销赠送、起案赠送订单选择的customer
      orderSales: {}, // 普通订单，数据暂存
    };
  },
  actions: {
    clearWeinaRecord() {
      this.weinaRecord = [];
    },
    updateWeinaRecord(array) {

      this.weinaRecord = array
      // array.forEach((element) => {
      //   this.weinaRecord.push(element);
      // });
    },
    updateGiftPackPurchase(array) {
      this.giftPackRecord.purchase = array
    },
    updateGiftPackPresent(array) {
      this.giftPackRecord.present = array
    },
    updatePromotion(array) {
      this.promotion = array
    },
    updateOrderSales(orderSales) {
      this.orderSales = orderSales
    },
    updateType(name) {
      if (name === "delete") {
        this.productRecord.type = [];
        this.productRecord.a = [];
        this.productRecord.b1 = [];
        this.productRecord.b2 = [];
        this.productRecord.c = [];
        return;
      }
      let index = this.productRecord.type.findIndex((item) => {
        return name === item;
      });
      if (index >= 0) return;
      this.productRecord.type.push(name);
    },
    updateA(array) {
      this.productRecord.a = array;
    },
    updateB1(array) {
      this.productRecord.b1 = array;
    },
    updateB2(array) {
      this.productRecord.b2 = array;
    },
    updateC(array) {
      this.productRecord.c = array;
    },
    updateRecord(obj) {
      this.record = obj;
    },
    updateRecordSec(obj) {
      this.recordSec = obj;
    },
    updateRouterRecord(router) {
      this.routerRecord.push(router);
      if (this.routerRecord.length >= 3) {
        this.routerRecord.shift();
      }
    },
    updateOrderCustomer(data) {
      this.orderCustomer = data
    }
  },
  // 数据持久化存储
  persist: true,
});
