/* eslint-disable no-unused-vars */
import axios from "./request";
import { ElLoading } from "element-plus";

// 定义一个请求次数的变量，用来记录当前页面总共请求的次数
let loadingRequestCount = 0;
// 初始化loading
let loadingInstance;
// 编写一个显示loading的函数 并且记录请求次数 ++
function showLoading() {
  if (loadingRequestCount === 0) {
    loadingInstance = ElLoading.service({ 
        lock: true, 
        text: '加载中……', 
        background: 'rgba(0, 0, 0, 0.7)'
    });
  }
  loadingRequestCount++
}

// 编写一个隐藏loading的函数，并且记录请求次数 --
function closeLoading() {
  if (loadingRequestCount <= 0) return
  loadingRequestCount--
  if (loadingRequestCount === 0) {
      loadingInstance.close();
  }
}

function request(url, params = {}, method = "GET", data = {}, hideLoad, responseType = null, headers = null) {
  if (!hideLoad) showLoading();
  let param = {
    url,
    method,
    params,
    data,
  }
  if (responseType) {
    param['responseType'] = responseType
  }
  if (headers) {
    param['headers'] = headers
  }
  return new Promise((resolve, reject) => {
    axios(param)
    .then((res) => {
      if (!hideLoad) closeLoading();
      if (responseType == 'blob') {
        let dis = res.headers['content-disposition'] ?? ''
        let fileName = dis?.split('attachment; filename=')[1] ?? ''
        if (fileName?.length >= 2) {
          fileName = fileName.substring(1, fileName.length - 1)
        }
        // 是文件，处理 data
        resolve({
          data: res.data,
          fileName: fileName
        })
      } else {
        resolve(res.data);
      }
    })
    .catch((error) => {
      if (!hideLoad) closeLoading();
      if (error.response) reject(error?.response.data);
    });
  });
}

export function get(url, params, hideLoad = false, responseType = null, headers = null) {
  return request(url, params, "GET", {}, hideLoad, responseType, headers);
}

export function del(url, data, hideLoad = false, responseType = null, headers = null) {
  return request(url, {}, "DELETE", data, hideLoad, responseType, headers);
}

export function post(url, data, hideLoad = false, responseType = null, headers = null) {
  return request(url, {}, "POST", data, hideLoad, responseType, headers);
}

export function put(url, data, hideLoad = false, responseType = null, headers = null) {
  return request(url, {}, "PUT", data, hideLoad, responseType, headers);
}
