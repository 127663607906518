import axios from "axios";
import { ElMessage } from "element-plus";
import { login } from "../store/pinia";
import router from "../router/index";
import { Urls } from "./apiSpaUrl";

// 忽略的错误
const ignore = {
  406: [
    {
      method: "post",
      pattern: "/users/staff/admin/login",
      url: "/users/staff/admin/login"
    }
  ],
  409: [
    {
      method: "delete",
      pattern: Urls.tag,
      url: Urls.tag
    }
  ]
}

// 1 默认的格式请求体中的数据会以json字符串的形式发送到后端
// 'Content-Type: application/json '
// 2 请求体中的数据会以普通表单形式（键值对）发送到后端
// 'Content-Type: application/x-www-form-urlencoded;charset=UTF-8'
// 3 它会将请求体的数据处理为一条消息，以标签为单元，用分隔符分开。既可以上传键值对，也可以上传文件
// 'Content-Type: multipart/form-data'

const baseURL = "https://api.benetiful.com.cn/api/v1";
// export const baseURL = "http://192.168.0.78:9000/api/v1";
// export const baseURL = "http://localhost:9000/api/v1";
export const fcBaseURL = "https://oms-htt-trigger-order-mt-system-hooihttnjl.cn-shanghai.fcapp.run";
const TimeOut = 1000 * 180;  // 请求接口超时时间
const ContentType = "application/json";

axios.defaults.baseURL = baseURL;
axios.defaults.timeout = TimeOut;
axios.defaults.headers["Content-Type"] = ContentType;

// 请求拦截器
axios.interceptors.request.use(
  function (config) {
    if (config.url.includes(fcBaseURL)) {
      // fc 的请求，携带 fc 的 token
      config.headers.Authorization = "Bearer " + login().other.fcJwt;
    } else if (login().jwt.accessToken) {
      // oms 的请求，携带 oms 的 token
      config.headers.Authorization = "Bearer " + login().jwt.accessToken;
    }

    // // 不需要 toke 的 请求url
    // const ignoreUrl = ["/users/staff/admin/login"];
    // if (ignoreUrl.indexOf(config.url) == -1) {
    //   // 是否有 token，有则请求，否则取消请求
    //   if (login().jwt.accessToken) {
    //     config.headers.Authorization = "Bearer " + login().jwt.accessToken;
    //   } else {
    //     const cancel = new axios.CancelToken(()=>{
    //     });
    //     config.cancelToken = cancel;
    //     cancel();
    //   }
    // }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 记录最后一次的错误信息
let lastMessage = ''
const Errormsg = (message) => {
  if (message == lastMessage) {
    return
  }
  // 记录最后一次信息，3秒后记录清除（优化，重复错误信息展示）
  lastMessage = message
  setTimeout(() => {
    lastMessage = ''
  }, 3000);
  ElMessage({
    message: message,
    type: "error",
  });
};

// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (error) => {
    console.log(error);
    if (!error.response) {
      Errormsg(error.message);
      return Promise.reject(error);
    }
    let data = error.response.data;
    // 单独毙掉订单延迟接口
    let urls = error.config.url.split("/");
    if ("rights" === urls[urls.length - 1]) return Promise.reject(error);

    const ignoreArr = ignore[data.statusCode] ?? []
    if (ignoreArr.length > 0) {
      const method = error.config.method;
      const url = error.config.url;
      for( const i in ignoreArr) {
        const item = ignoreArr[i];
        var pattern = new RegExp(item.pattern);
        if (item.method != method || !pattern.test(url)) {
          break;
        }
        return Promise.reject(error);
      }
    }

    switch (data.statusCode) {
      case 400:
        Errormsg(data.message);
        break;
      case 401:
        Errormsg("未经授权，请重新登录");
        login().clearData();
        router.replace("/login");
        break;
      case 404:
        Errormsg(data.message);
        break;
      case 406:
        Errormsg(data.message);
        break;
      case 409:
        Errormsg(data.message);
        break;
      case 412:
        Errormsg(data.message);
        break;
      case 429:
        Errormsg("请求太多，请稍后再试");
        break;
      case 500:
        Errormsg(data.message);
        router.replace("/404");
        break;
      default:
        Errormsg(`未知错误信息: ${data.message}`);
    }
    return Promise.reject(error);
  }
);

export default axios;
