<script setup>
import { onMounted, ref, getCurrentInstance, reactive } from "vue";
import layoutHeaderVue from "./layout/layoutHeader.vue";
import layoutSider from "./layout/layoutSider.vue";
import message from "@/components/message.vue";
const { proxy } = getCurrentInstance();

// 导航
const Navigation = ref("");
const BackNavigation = ref(false);
const updateHeader = (e) => {
  Navigation.value = e.title;     // 更新标题
  BackNavigation.value = e.back;  // 是否可以后退
}

const backReturn = () => {
  proxy.$router.back();
  BackNavigation.value = false;
};

// 尺寸
const screenHeight = ref();
const oldScreenHeight = ref(50);
const getWindowInfo = () => (screenHeight.value = window.innerHeight);

// ref
const reflayoutSider = ref();

// 消息提示
const refMessage = ref();
const messageProps = reactive({
  start: 0,
  content: "操作成功",
});
const messageTimer = ref();
/**
 * 弹窗提醒
 * 
 * @param obj.start 弹窗类型 0：成功；1：错误；2：警告
 * @param obj.content 底部展示内容 
 * @param obj.time 自动消失时间，当为 0 时不会自动消失，需要手动点击右上角关闭按钮 
*/
const msgLogic = (obj) => {
  messageProps.start = obj?.start ?? 0;
  messageProps.content = obj?.content ?? "操作成功";
  refMessage.value.visible = true;

  let time = obj?.time ?? 0
  if (time > 0) {
    if (messageTimer.value) {
      clearTimeout(messageTimer.value);
      messageTimer.value = null;
    }
    messageTimer.value = setTimeout(() => {
      refMessage.value.visible = false;
      messageTimer.value = null;
    }, time * 1000);
  }
  
};

// 用户信息更新头像成功
const refLayoutHeader =ref()
const imgUploadOk =()=>{
  refLayoutHeader.value.getUserInfo()
}

onMounted(() => {
  window.addEventListener("resize", getWindowInfo);
  screenHeight.value = window.innerHeight;
  reflayoutSider.value.setLayout();
});
</script>

<template>
  <div>
    <div class="homeView">
      <message
        ref="refMessage"
        :start="messageProps.start"
        :content="messageProps.content"
      ></message>
      <a-layout>
        <a-layout-header>
          <layoutHeaderVue ref="refLayoutHeader"></layoutHeaderVue>
        </a-layout-header>
        <a-layout class="layout">
          <a-layout-sider class="layout_sider">
            <layoutSider @updateHeader="updateHeader" ref="reflayoutSider"></layoutSider>
          </a-layout-sider>
          <a-layout-content
            class="vn_overflow_auto layout-content-max"
            :style="{ height: screenHeight - oldScreenHeight + 'px' }"
          >
            <div class="layout_content_nav" v-if="Navigation">
              <img
                class="cur"
                src="./../assets/images/image/icon_back.png"
                v-if="BackNavigation"
                @click="backReturn"
              />
              <span class="title">
              {{ Navigation }}
              </span>
            </div>
            <div class="layout-content">
              <router-view
                @msg="msgLogic"
                @updateHeader="updateHeader"
                @imgUploadOk="imgUploadOk"
              ></router-view>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.layout_content_nav {
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 999;
  width: 100%;
  height: 50px;
  background: #ffffff;
  font-size: 14px;
  font-weight: 400;
  color: #141414;
  line-height: 50px;
  padding-left: 20px;
  img {
    width: 14.72px;
    height: 13.77px;
    margin-right: 11px;
    transform: translateY(-1px);
  }
  .title {
    font-size: 16px;
    font-weight: 700;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #53616f;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  border-radius: 0;
  background: #263748;
}
.layout-content {
  padding: 16px 60px 16px 16px;
  position: relative;
}
.homeView {
  height: 100vh;
}
.ant-layout-content {
  background: #f3f4f7;
  text-align: left;
}
.ant-layout-sider {
  background: #1f212d;
}
.ant-layout-header {
  background: #272f3c;
  height: 50px;
  padding: 0px;
}
.ant-layout {
  height: 100vh;
}
// .ant-layout .ant-layout :not(.map_container) {
//   box-sizing: border-box !important;
// }
</style>
