<script setup>
import { ref, reactive, getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance()
const emit = defineEmits(["confirm"])

const first = ref(false);
const visible = ref(false);
const dataObj = reactive({
  password: "",
  newPassword: "",
  confirmPassword: "",
});

// 修改密码确认键
const confirm = () => {
  if (!dataObj.password) {
    proxy.$tool.msgWarning("原密码不能为空");
    return;
  };

  if (!dataObj.newPassword) {
    console.log(dataObj.newPassword)
    proxy.$tool.msgWarning("新密码不能为空");
    return;
  } else {
    const check = /^(?=.{6})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d).*$/;
    if (check.test(dataObj.newPassword) == false) {
      proxy.$tool.msgWarning("字母大、小写和数字的组合,长度至少6位");
      return;
    }
  }
  if (!dataObj.confirmPassword) {
    proxy.$tool.msgWarning("确认密码不能为空");
    return;
  };
  if(dataObj.newPassword !== dataObj.confirmPassword){
    proxy.$tool.msgWarning("新密码、确认密码不一致");
    return;
  }
  emit("confirm", dataObj)
};
const close = () => {
  visible.value = false;
};

defineExpose({
  visible,
  first
});
</script>

<template>
  <div>
    <a-modal v-model:visible="visible" centered width="800px" :footer="null" destroyOnClose>
      <div class="vn_modal_edit_data">
        <div class="vn_modal_edit_data_title">
          修改密码
          <p class="first_edit_password_tips" v-if="first">
            为保证账号安全，首次登录请立即修改密码
          </p>
        </div>
        <div class="vn_modal_edit_data_item" v-if="!first">
          <p class="vn_modal_edit_data_item_title">原密码</p>
          <a-input v-model:value="dataObj.password" class="vn_ant-input" type="password" placeholder="请输入原密码" />
        </div>
        <div class="vn_modal_edit_data_item">
          <p class="vn_modal_edit_data_item_title">新密码</p>
          <a-input v-model:value="dataObj.newPassword" class="vn_ant-input" type="password" placeholder="请输入新密码" />
        </div>
        <div class="vn_modal_edit_data_item">
          <p class="vn_modal_edit_data_item_title">确认密码</p>
          <a-input v-model:value="dataObj.confirmPassword" class="vn_ant-input" type="password" placeholder="请输入确认密码" />
        </div>
        <div class="vn_modal_edit_data_button vn_flex">
          <a-button type="primary" class="vn_confirm_button_90" @click="confirm">确认</a-button>
          <p class="vn_width_20"></p>
          <a-button class="vn_close_button_90" @click="close">取消</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<style lang="scss" scoped>
.first_edit_password_tips {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 5px;
}
</style>