<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { ref } from "vue";

const visible = ref(false);

const props = defineProps({
  start: {
    type: Number,
    default:0
    // 0：正确。1：错误，2：警告
  },
  content: {
    type: String
  },
});

defineExpose({
  visible
})
</script>

<template>
  <div>
    <a-modal v-model:visible="visible" :footer="null" centered width="800px">
      <div class="message vn_padding_20">
        <img src="@/assets/images/message/0.png" v-if="props.start  === 0" />
        <img src="@/assets/images/message/1.png" v-else-if="props.start  === 1" />
        <img src="@/assets/images/message/2.png" v-else-if="props.start  === 2" />
        <p class="start">
          {{
            props.start === 0 ? "成功" : (props.start === 1 ? "错误" : "警告")
          }}
        </p>
        <p class="content">{{ props.content }}</p>
      </div>
    </a-modal>
  </div>
</template>

<style lang="scss" scoped>
.message {
  text-align: center;
  padding: 90px 0px;
  .content {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    margin-top: 6px;
  }
  .start {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin-top: 20px;
  }
  img {
    width: 44px;
    height: 44px;
  }
}
</style>