import { createApp } from "vue";
import { createPinia } from "pinia";
import * as piniaJs from "@/store/pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router";
import Antd from "ant-design-vue";
import ElementPlus from "element-plus";
import tool from "./assets/js/main";
import config from "./assets/js/config";
import { useRouter, useRoute } from "vue-router";
import * as echarts from "echarts";
import * as upOssImg from "@/utils/alioss";
import * as api from "./utils/api";
import * as apiSpa from "./utils/apiSpa";
import "ant-design-vue/dist/antd.css";
import "element-plus/dist/index.css";
import "@/assets/css/main.scss";
import "@/assets/css/color.scss";
import "vue3-tree-org/lib/vue3-tree-org.css";

import vue3TreeOrg from 'vue3-tree-org';
import nvUpload from "@/components/nvUpload.vue";
import paginationVue from "@/components/pagination.vue";

const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

app.config.globalProperties.$router = useRouter();
app.config.globalProperties.$route = useRoute();
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$piniaJs = piniaJs;
app.config.globalProperties.$tool = tool;
app.config.globalProperties.$config = config;
app.config.globalProperties.$api = api;
app.config.globalProperties.$apiSpa = apiSpa;
app.config.globalProperties.$oss = upOssImg;

app.component("nvUpload", nvUpload);
app.component("paginationVue", paginationVue);

app.use(pinia).use(router).use(ElementPlus).use(Antd).use(vue3TreeOrg).mount("#app");
