<template>
  <ElConfigProvider :locale="zhCn_e">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </ElConfigProvider>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";

dayjs.locale("zh-cn");
import { ElConfigProvider } from "element-plus";

import zhCn_e from "element-plus/dist/locale/zh-cn.mjs";

export default {
  components: {
    ElConfigProvider,
  },
  data() {
    return {
      locale: zhCN,
      zhCn_e: zhCn_e,
    };
  },
};
</script>

<style lang="scss">

.el-table__inner-wrapper {
  border: none !important;
}

.el-table__row {
  .el-table__cell {
    .cell {
      .ant-image {
        display: block !important;
      }
    }
  }
}

.el-input__wrapper {
  border-radius: 2px !important;
  cursor: text !important;

  .el-input__inner {
    font-size: 12px;
    cursor: inherit !important;
  }
}

.ant-picker-input {
  input {
    font-size: 12px !important;
  }
}

.ant-radio-button-wrapper {
  font-size: 12px;
}

.el-radio__inner {
  width: 18px !important;
  height: 18px !important;
}

.el-radio__inner::after {
  width: 9px !important;
  height: 9px !important;
}

.el-radio__label {
  color: #999999;
  font-size: 12px !important;
}

.is-checked {
  .el-radio__label {
    color: #141414 !important;
  }
}

.el-table__inner-wrapper {
  border: 1px solid #efefef;
}

.el-table__header {
  .el-table__cell {
    background: #fafafa !important;
  }
}

.el-table > .el-table--large {
  font-size: 12px;
}

.el-table--large {
  .el-table__cell {
    padding: 10px 0  !important;
  }
  .cell {
    padding: 0px !important;
  }
}

.el-table {
  --el-table-header-text-color: rgba(0,0,0,.4) !important;
  .warning-row {
    --el-table-tr-bg-color: #FDF6ED;
  }
  .progress-row {
    --el-table-tr-bg-color: #efefef;
  }
}

.el-checkbox__label {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.ant-dropdown-content {
  width: 140px;
}

// 修改 a-radio-group 中字体大小
.ant-radio-group > .ant-radio-button-wrapper {
  font-size: 12px !important;
}

.ant-select,
.ant-input, 
.ant-input-number, 
.ant-dropdown-menu-title-content, 
.ant-popover-message-title, 
.ant-btn-sm, 
.ant-checkbox-wrapper, 
.ant-modal-body, 
.ant-select-item-option-content {
  font-size: 12px !important;
}

.clusterBubble {
  border-radius: 50%;
  font-weight: 500;
  text-align: center;
  opacity: 0.88;

  border-width: 1px;
  border-style: solid;
  border-color: #385dd7;
  background-color: #fff;
  color: #295BFF;

  background-image: linear-gradient(139deg, rgba(34, 34, 231, 0.086) 0%, rgba(34, 34, 231, 0.086) 100%);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.20);
  position: absolute;
  top: 0px;
  left: 0px;
  user-select: none; /* 标准浏览器 */
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Safari 和 Chrome */
  -ms-user-select: none; /* IE/Edge */
  -khtml-user-select: none; /* Konqueror */
}
</style>
