/* eslint-disable no-unused-vars */
import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { login, record } from "../store/pinia";
import tool from "../assets/js/main";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/storeList",
    name: "storeList",
    meta: {
    },
    // component: () => import("@/views/basicManagement/storeList.vue"),
    component: () => import("@/views/basicManagement/storeListNew.vue"),
  },
  {
    path: "/404",
    name: "notFound",
    component: () => import("@/views/error/404.vue"),
  },
  {
    path: "/gameLive",
    name: "gameLive",
    meta: {
    },
    component: () => import("@/views/game/gameLive.vue"),
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/redirect",
    children: [
      {
        path: "/redirect",
        name: "redirect",
        meta: {
        },
        component: () => import("@/views/redirect.vue"),
      },
      {
        path: "/homePage",
        name: "homepage",
        meta: {
        },
        component: () => import("@/views/homePage/index.vue"),
      },
      {
        path: "/userinfo",
        name: "userinfo",
        meta: {
        },
        component: () => import("@/views/userInfo/index.vue"),
      },
      {
        path: "/productList",
        name: "productList",
        meta: {
        },
        component: () => import("@/views/basicManagement/productList.vue"),
      },
      {
        path: "/product",
        name: "product",
        meta: {
        },
        component: () => import("@/views/basicManagement/product.vue"),
      },
      {
        path: "/expertAdvice",
        name: "expertAdvice",
        meta: {
        },
        component: () => import("@/views/basicManagement/expertAdvice.vue"),
      },
      {
        path: "/expertAdviceEdit",
        name: "expertAdviceEdit",
        meta: {
        },
        component: () => import("@/views/basicManagement/expertAdviceEdit.vue"),
      },
      {
        path: "/agentMarketer",
        name: "agentMarketer",
        meta: {
        },
        component: () => import("@/views/systemManagement/agent_marketer.vue"),
      },
      {
        path: "/agentLeader",
        name: "agentLeader",
        meta: {
        },
        component: () => import("@/views/systemManagement/agent_leader.vue"),
      },
      {
        path: "/agentCompany",
        name: "agentCompany",
        meta: {
        },
        component: () => import("@/views/systemManagement/agent_company.vue"),
      },
      {
        path: "/organizationalManagement",
        name: "organizationalManagement",
        meta: {
        },
        component: () => import("@/views/systemManagement/organizational_management.vue"),
      },
      {
        path: "/seeAgent",
        name: "seeAgent",
        meta: {
        },
        component: () => import("@/views/systemManagement/agent_see.vue"),
      },
      {
        path: "/batchCenter",
        name: "batchCenter",
        meta: {
        },
        component: () => import("@/views/systemManagement/batch/batch_center.vue"),
      },
      {
        path: "/Franchisee",
        name: "Franchisee",
        meta: {
        },
        component: () => import("@/views/storeManagement/Franchisee.vue"),
      },
      {
        path: "/seeFranchisee",
        name: "seeFranchisee",
        meta: {
        },
        component: () => import("@/views/storeManagement/Franchisee_see.vue"),
      },
      {
        path: "/spaReview",
        name: "spaReview",
        meta: {
        },
        component: () => import("@/views/storeManagement/spa_review.vue"),
      },
      {
        path: "/customerList",
        name: "customerList",
        meta: {
        },
        component: () => import("@/views/storeManagement/customerList.vue"),
      },
      {
        path: "/organizationalChart",
        name: "organizationalChart",
        meta: {
        },
        component: () => import("@/views/systemManagement/organizational_chart.vue"),
      },
      {
        path: "/qualificationFranchisee",
        name: "qualificationFranchisee",
        meta: {
        },
        component: () => import("@/views/storeManagement/Franchisee_qualification.vue"),
      },
      {
        path: "/staff",
        name: "staff",
        meta: {
        },
        component: () => import("@/views/systemManagement/staff.vue"),
      },
      {
        path: "/staff_add",
        name: "staff_add",
        meta: {
        },
        component: () => import("@/views/systemManagement/staff_add.vue"),
      },
      {
        path: "/role",
        name: "role",
        meta: {
        },
        component: () => import("@/views/systemManagement/role.vue"),
      },
      {
        path: "/roleAdd",
        name: "roleAdd",
        meta: {
        },
        component: () => import("@/views/systemManagement/role_add.vue"),
      },
      {
        path: "/routine",
        name: "routine",
        meta: {
        },
        component: () => import("@/views/systemManagement/routine.vue"),
      },
      {
        path: "/routineEdit",
        name: "routineEdit",
        meta: {
        },
        component: () => import("@/views/systemManagement/routine_edit.vue"),
      },
      {
        path: "/imageAnalyze",
        name: "imageAnalyze",
        meta: {
        },
        component: () => import("@/views/test/imageAnalyze/index.vue"),
      },

      {
        path: "/productPromotion",
        name: "productPromotion",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/product/index.vue"),
      },
      {
        path: "/addPromotion",
        name: "addPromotion",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/product/addPromotion.vue"),
      },
      {
        path: "/addWeina",
        name: "addWeina",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/weinaPromotion/addWeina.vue"),
      },
      {
        path: "/weinaPromotion",
        name: "weinaPromotion",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/weinaPromotion/index.vue"),
      },
      {
        path: "/spaPromotion",
        name: "spaPromotion",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/spaPromotion/index.vue"),
      },
      {
        path: "/addSpa",
        name: "addSpa",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/spaPromotion/addSpa.vue"),
      },
      {
        path: "/raffleList",
        name: "raffleList",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/raffle/index.vue"),
      },
      {
        path: "/addRaffle",
        name: "addRaffle",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/raffle/addRaffle.vue"),
      },
      {
        path: "/raffleEditProduct",
        name: "raffleEditProduct",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/raffle/raffleEditProduct.vue"),
      },
      {
        path: "/raffleStoreList",
        name: "raffleStoreList",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/raffle/raffleStoreList.vue"),
      },
      {
        path: "/raffleSceneList",
        name: "raffleSceneList",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/raffle/raffleSceneList.vue"),
      },
      {
        path: "/raffleStoreDetail",
        name: "raffleStoreDetail",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/raffle/raffleStoreDetail.vue"),
      },
      {
        path: "/giftPackage",
        name: "giftPackage",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/giftPackage/index.vue"),
      },
      {
        path: "/addGift",
        name: "addGift",
        meta: {
        },
        component: () =>
          import("@/views/promotionManagement/giftPackage/addGift.vue"),
      },
      {
        path: "/editProduct",
        name: "editProduct",
        meta: {
        },
        component: () => import("@/views/promotionManagement/editProduct.vue"),
      },
      {
        path: "/orderManagement",
        name: "orderManagement",
        meta: {
        },
        component: () => import("@/views/orderManagement/index.vue"),
      },
      {
        path: "/orderDetails",
        name: "orderDetails",
        meta: {
        },
        component: () => import("@/views/orderManagement/details.vue"),
      },
      {
        path: "/orderProductReplace",
        name: "orderProductReplace",
        meta: {
        },
        component: () => import("@/views/orderManagement/orderProductReplace.vue"),
      },
      {
        path: "/orderGiftPack",
        name: "orderGiftPack",
        meta: {
        },
        component: () => import("@/views/orderManagement/giftPack.vue"),
      },
      {
        path: "/orderGiftPackEdit",
        name: "orderGiftPackEdit",
        meta: {
        },
        component: () => import("@/views/orderManagement/giftPackEditProduct.vue"),
      },
      {
        path: "/orderPromotion",
        name: "orderPromotion",
        meta: {
        },
        component: () => import("@/views/orderManagement/orderPromotion.vue"),
      },
      {
        path: "/orderPromotionEdit",
        name: "orderPromotionEdit",
        meta: {
        },
        component: () => import("@/views/orderManagement/orderPromotionEditProduct.vue"),
      },
      {
        path: "/orderSales",
        name: "orderSales",
        meta: {
        },
        component: () => import("@/views/orderManagement/orderSales.vue"),
      },
      {
        path: "/orderSalesEdit",
        name: "orderSalesEdit",
        meta: {
        },
        component: () => import("@/views/orderManagement/orderSalesEditProduct.vue"),
      },
      {
        path: "/goodsDetail",
        name: "goodsDetail",
        meta: {
        },
        component: () => import("@/views/orderManagement/orderDetail.vue"),
      },
      {
        path: "/orderReplacePromotion",
        name: "orderReplacePromotion",
        meta: {
        },
        component: () => import("@/views/orderManagement/orderReplacePromotion.vue"),
      },
      {
        path: "/crouselChart",
        name: "crouselChart",
        meta: {
        },
        component: () => import("@/views/basicManagement/crouselChart.vue"),
      },
      {
        path: "/crouselChart_add",
        name: "crouselChart_add",
        meta: {
        },
        component: () => import("@/views/basicManagement/crouselChart_add.vue"),
      },
      {
        path: "/homeExtension",
        name: "homeExtension",
        meta: {
        },
        component: () => import("@/views/basicManagement/homeExtension.vue"),
      },
      {
        path: "/Extension_edit",
        name: "Extension_edit",
        meta: {
        },
        component: () =>
          import("@/views/basicManagement/homeExtension_edit.vue"),
      },
      {
        path: "/erpCompany",
        name: "erpCompany",
        meta: {
        },
        component: () => import("@/views/systemManagement/erp_company.vue"),
      },
      {
        path: "/salesYear",
        name: "salesYear",
        meta: {
        },
        component: () => import("@/views/salesAnalysis/sales_year.vue"),
      },
      {
        path: "/rankingList",
        name: "rankingList",
        meta: {
        },
        component: () => import("@/views/salesAnalysis/ranking_list.vue"),
      },
      {
        path: "/salesMonth",
        name: "salesMonth",
        meta: {
        },
        component: () => import("@/views/salesAnalysis/sales_month.vue"),
      },
      {
        path: "/organization",
        name: "organization",
        meta: {
        },
        component: () => import("@/views/salesAnalysis/organization.vue"),
      },
      {
        path: "/salesStoreList",
        name: "salesStoreList",
        meta: {
        },
        component: () => import("@/views/salesAnalysis/sales_store_list.vue"),
      },
      {
        path: "/salesStore",
        name: "salesStore",
        meta: {
        },
        component: () => import("@/views/salesAnalysis/sales_store.vue"),
      },
      {
        path: "/salesStoreDetail",
        name: "salesStoreDetail",
        meta: {
        },
        component: () => import("@/views/salesAnalysis/sales_store_detail.vue"),
      },
      {
        path: "/salesMarketDetail",
        name: "salesMarketDetail",
        meta: {
        },
        component: () => import("@/views/salesAnalysis/sales_market_detail.vue"),
      },
      {
        path: "/salesMarketer",
        name: "salesMarketer",
        meta: {
        },
        component: () => import("@/views/salesAnalysis/sales_marketer.vue"),
      },
      {
        path: "/salesProductList",
        name: "salesProductList",
        meta: {
        },
        component: () => import("@/views/salesAnalysis/sales_product_list.vue"),
      },
      {
        path: "/salesHome",
        name: "salesHome",
        meta: {
        },
        component: () => import("@/views/salesAnalysis/sales_home.vue"),
      },
      // {
      //   path: "/gameLive",
      //   name: "gameLive",
      //   meta: {
      //   },
      //   component: () => import("@/views/game/gameLive.vue"),
      // },
      
      



      /*  ======================== spa 小程序页面 =======================  */
      {
        path: "/spa/banner",
        name: "spaBanner",
        meta: {
        },
        component: () => import("@/views/spa/banner/index.vue"),
      },
      {
        path: "/spa/banner/add",
        name: "spaBannerAdd",
        meta: {
        },
        component: () => import("@/views/spa/banner/indexAdd.vue"),
      },

      {
        path: "/spa/material",
        name: "spaMaterial",
        meta: {
        },
        component: () => import("@/views/spa/material/index.vue"),
      },
      {
        path: "/spa/material/add",
        name: "spaMaterialAdd",
        meta: {
        },
        component: () => import("@/views/spa/material/indexAdd.vue"),
      },
      {
        path: "/spa/tagManager",
        name: "spaTagManager",
        meta: {
        },
        component: () => import("@/views/spa/tagManager/index.vue"),
      },
      {
        path: "/spa/tagManager/add",
        name: "spaTagManagerAdd",
        meta: {
        },
        component: () => import("@/views/spa/tagManager/indexAdd.vue"),
      },
      {
        path: "/spa/content/list",
        name: "spaContentList",
        meta: {
        },
        component: () => import("@/views/spa/content/indexList.vue"),
      },
      {
        path: "/spa/content/tab",
        name: "spaContentTab",
        meta: {
        },
        component: () => import("@/views/spa/content/indexTab.vue"),
      },
      {
        path: "/spa/content/fixed/list",
        name: "spaContentFixedList",
        meta: {
        },
        component: () => import("@/views/spa/content/indexFixedList.vue"),
      },
      {
        path: "/spa/content/add",
        name: "spaContentAdd",
        meta: {
        },
        component: () => import("@/views/spa/content/indexAdd.vue"),
      },
      {
        path: "/spa/menuCover",
        name: "spaMenuCover",
        meta: {
        },
        component: () => import("@/views/spa/menuCover/index.vue"),
      },
      {
        path: "/spa/menuCover/edit",
        name: "spaMenuCoverEdit",
        meta: {
        },
        component: () => import("@/views/spa/menuCover/indexEdit.vue"),
      },
      {
        path: "/noticesManager",
        name: "noticesManager",
        meta: {
        },
        component: () => import("@/views/basicManagement/noticesManager.vue"),
      },
      {
        path: "/notices_add",
        name: "notices_add",
        meta: {
        },
        component: () => import("@/views/basicManagement/notices_add.vue"),
      },
      {
        path: "/serviceManager",
        name: "serviceManager",
        meta: {
        },
        component: () => import("@/views/basicManagement/serviceManager.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 路由前置守卫
router.beforeEach(async (to, from) => {
  // 忽略检查是否登录的页面
  if (!['storeList', 'gameLive', 'notFound'].includes(to.name)) {
    if (login().getLoginEndTime === 301) {
      if (to.name !== "login") {
        if (login().loginEndTime > 0) tool.msgError("登录信息已过期！请重新登录");
        return {
          name: "login",
        };
      }
    }
  }
});

// 路由后置守卫
router.afterEach((to, from) => {
  record().updateRouterRecord(to.name);
});

export default router;
