
export class Urls {
    // 标签
    static tag = "/spa/admin/tags"
    static tag_id = (id) => {
        return `/spa/admin/tags/${id}`
    }
    
    // 内容
    static content = "/contents/admin/spa"
    static content_search = "/contents/admin/spa/search"
    static content_id = (id) => {
        return `/contents/admin/spa/${id}`
    }

    // banner
    static banner = "/banner/admin/spa"
    static banner_id = (id) => {
        return `/banner/admin/spa/${id}`
    }

    // 菜单
    static menu = "/spa/admin/menu"
    static menu_id = (id) => {
        return `/spa/admin/menu/${id}`
    }
}