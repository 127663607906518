<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import {
  CaretDownOutlined,
  StepBackwardOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  StepForwardOutlined,
} from "@ant-design/icons-vue";
import { ref, watch } from "vue";

const emit = defineEmits(["change", "changePage"]);

const props = defineProps({
  total: {
    type: Number,
    default: 0,
  },
  page: {
    type: Number,
    default: 1,
  },
  pageSize: {
    type: Number,
    default: 50,
  },
  pageCount: {
    type: Number,
  },
});

const iconStyle = {
  color: "#888888",
};

// 监听页数
watch(
  () => props.pageSize,
  () => {
    selectedKeys.value = [props.pageSize];
  }
);

// 返回上级要展示的页数
const changePage = (e) => {
  emit("changePage", e);
};

// 首页 / 上一页
const firstPage = (e = 0) => {
  if (props.page <= 1) return;
  if (e === 1) {
    changePage(props.page - 1);
  } else {
    changePage(1);
  }
};
// 尾页 / 下一页
const lasePage = (e = 0) => {
  if (props.page >= props.pageCount) return;
  if (e === 1) {
    changePage(props.page + 1);
  } else {
    changePage(props.pageCount);
  }
};

// 默认选择条数
const selectedKeys = ref([50]);
// 切换展示条数
const MenuClick = (e) => {
  emit("change", e.key);
};

watch(() => props.page,
  () => {
    inputPage.value = props.page
  })
const inputPage = ref(props.page)
const inputBlur = (e) => {
  if (props.page === inputPage.value) return;
  if (e?.key === "Enter" || e === "Enter") {
    if (inputPage.value > props.pageCount) inputPage.value = props.pageCount
    if (!inputPage.value || inputPage.value <= 0) inputPage.value = 1
    emit("changePage", inputPage.value);
  }
}
</script>

<template>
  <div class="vn_flex_j main">
    <div class="total vn_size_12">共 {{ props.total }} 条</div>
    <div class="vn_flex_items">
      <a-dropdown>
        <div class="cur">
          <span class="pageSize">{{ props.pageSize }} </span>
          <caret-down-outlined :style="iconStyle" />
        </div>
        <template #overlay>
          <a-menu v-model:selectedKeys="selectedKeys" style="width: 95px; line-height: 1.5715" @click="MenuClick">
            <a-menu-item :key="50"> 50 条/页 </a-menu-item>
            <a-menu-item :key="100"> 100 条/页 </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <span class="pageSize_tips"> 条/页</span>
      <div class="vn_width_20"></div>
      <div class="foot flex">
        <div class="foot_box cur" @click="firstPage">
          <step-backward-outlined :style="iconStyle" />
        </div>
        <div class="foot_box cur" @click="firstPage(1)">
          <caret-left-outlined :style="iconStyle" />
        </div>
        <div class="input_size vn_flex">
          <!-- <a-input class="input"></a-input> -->
          <el-input-number class="input" v-model="inputPage" :controls="false" @blur="inputBlur('Enter')" :min="1"
            @keyup="inputBlur" />
          <!-- <div class="input">{{ props.page }}</div> -->
          <div class="size">/{{ props.pageCount }}页</div>
        </div>
        <div class="foot_box cur" @click="lasePage(1)">
          <caret-right-outlined :style="iconStyle" />
        </div>
        <div class="foot_box cur" @click="lasePage">
          <step-forward-outlined :style="iconStyle" />
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.main {
  .total {
    font-weight: 400;
    color: #aaaaaa;
  }

  .foot {
    border-top: 1px solid #dadfe6;
    border-bottom: 1px solid #dadfe6;

    .input_size {
      :deep(.el-input__wrapper){
        box-shadow: none !important;
      }
      .input {
        height: 32px;
        width: 70px !important;
        line-height: 32px;
        cursor: pointer;
        border: none;
        text-align: center;
        user-select: none;
      }

      .size {
        user-select: none;
        width: 80px;
        height: 100%;
        background: #f3f4f7;
        line-height: 30px;
        text-align: center;
        color: #141414;
        font-size: 12px;
        letter-spacing: 1px;
        border-top: 1px solid #f3f4f7;
        border-bottom: 1px solid #f3f4f7;
      }
    }

    &_box {
      width: 31px;
      height: 100%;
      background: #e7eaee;
      text-align: center;
      line-height: 32px;
      border-right: 1px solid #dadfe6;
      border-left: 1px solid #dadfe6;
    }
  }

  .pageSize_tips {
    color: #a1a1a1;
    font-size: 12px;
    margin-left: 7px;
    letter-spacing: 6px;
  }

  .pageSize {
    color: #141414;
    font-size: 12px;
    margin-right: 7px;
  }
}
</style>