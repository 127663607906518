import { ElMessage } from "element-plus";
import dayjs from "dayjs";
const _ = require('lodash');

// 促销管理tabs列表
const promotion = [
  {
    key: 1,
    name: "待提交",
    value: "draft",
  },
  {
    key: 2,
    name: "待审核",
    value: "submit",
  },
  {
    key: 3,
    name: "待发布",
    value: "adopt",
  },
  {
    key: 4,
    name: "待激活",
    value: "release",
  },
  {
    key: 5,
    name: "已上架",
    value: "active",
  },
  {
    key: 6,
    name: "已下架",
    value: "offline",
  },
];

// 促销管理tabs列表 查看权限
const promotionCheck = [
  {
    key: 1,
    name: "已上架",
    value: "active",
  }
];

// 订单管理tabs列表
const orderTabs = [
  {
    key: 1,
    name: "秘书待审",
  },
  {
    key: 2,
    name: "总部预审",
  },
  {
    key: 3,
    name: "财务待审",
  },
  {
    key: 4,
    name: "SPA待审",
  },
  {
    key: 5,
    name: "准备出库",
  },
  {
    key: 6,
    name: "完成出库",
  },
  {
    key: 7,
    name: "申请变更",
  },
  {
    key: 0,
    name: "全部",
  },
];

// 店铺管理tabs列表
// auditing_status：
// 	0：未审核
// 	1：审核通过
// 	2：审核失败
// 	3：初审成功
// 	4：待初审
const storeTabs = [
  {
    status: 0,
    name: "未审核",
    time: "创建时间",
  },
  {
    status: 4,
    name: "待初审",
    time: "更新时间",
  },
  {
    status: 3,
    name: "初审通过",
    time: "初审时间",
  },
  {
    status: 1,
    name: "终审通过",
    time: "终审时间",
  },
  {
    status: 2,
    name: "审核失败",
    time: "更新时间",
  },
  {
    status: 6,
    name: "注册失败",
    time: "更新时间",
  },
  {
    status: 7,
    name: "回收站",
    time: "更新时间",
  },
  // {
  //   status: 5,
  //   name: "SPA审核",
  //   time: "创建时间",
  // }
];

// SPA 标签类型
const spaTagType = [
  {
    value: "home",
    label: "首页介绍",
  },
  {
    value: "data",
    label: "资料查看",
  },
  // {                  // 暂时隐藏 总部标签
  //   value: "headquarters",
  //   label: "总部",
  // },
]


// 抽奖状态
const raffleStatus = {
  pending: "pending", // 待开启
  active: "active", // 开启
  abort: "abort", // 暂停
  close: "close", // 终止
}
const raffleStatusList = [
  {
    value: raffleStatus.pending,
    name: "待开启",
    color: ""
  },
  {
    value: raffleStatus.active,
    name: "进行中",
    color: "color_09b109"
  },
  {
    value: raffleStatus.abort,
    name: "暂停",
    color: "color_FE993C"
  },
  {
    value: raffleStatus.close,
    name: "结束",
    color: "color_E30E0E"
  }
]

// 抽奖名称
const raffleStatusName = (status) => {
  let name = '-';
  raffleStatusList.forEach(item => {
    if (item.value == status) {
      name = item.name;
      return;
    }
  })
  return name;
}

// 抽奖名称
const raffleStatusColor = (status) => {
  let color = '';
  raffleStatusList.forEach(item => {
    if (item.value == status) {
      color = item.color;
      return;
    }
  })
  return color;
}

// ElementMsg提示
const msg = (message) => msgLg(message);
const msgSuccess = (message) => msgLg(message, "success");
const msgWarning = (message) => msgLg(message, "warning");
const msgError = (message) => msgLg(message, "error");
const msgLg = (message, type) => {
  ElMessage(
    type
      ? {
          type,
          message,
        }
      : message
  );
};

// 重置筛选内容
const reset = (params) => {
  const keys = Object.keys(params);
  let obj = {};
  keys.forEach((item) => {
    if (item == "page") obj[item] = 1;
    else if (item == "take") obj[item] = 50;
    else obj[item] = "";
  });
  return obj;
};

// 静态资源地址
const url =
  "http://h31202924.oss-cn-shanghai.aliyuncs.com/uploadFiles/products/";  // 产品图
const ossUrl = "https://weina-oms.oss-cn-shanghai.aliyuncs.com/";
const lbsOssUrl = "https://weina-lbs.oss-cn-shanghai.aliyuncs.com/";
const dataOssUrl = "https://weina-data.oss-cn-shanghai.aliyuncs.com";
const resource = {
  noData: 'img/resource/no_data.jpg'
}

// 防抖
let debounceTImer = null;
const debounce = (fun, delay = 500) => {
  return function () {
    if (debounceTImer) {
      clearTimeout(debounceTImer);
      debounceTImer = null;
    }
    debounceTImer = setTimeout(() => {
      fun();
    }, delay);
  };
};

// 节流
let timer = null;
function throttle(fn, delay = 300) {
  if (timer == null) {
    timer = setTimeout(() => {
      fn();
      clearTimeout(timer);
      timer = null;
    }, delay);
  }
}

// 禁选当日以及以前的时间
const disabledDateBefore = (current) => {
  return current && current < dayjs().endOf("day");
};

// 禁选以后的时间
const disabledDateAfter = (current) => {
  return current > dayjs().endOf("day");
};

/**
 * 格式化金额,每三位之间用逗号分割
 * @param {金额} num
 * @param {小数后截取位数} fixed
 * @returns
 */
function toThousands(num, fixed = 2) {
  if (typeof num === "number" && num >= 0) {
    num = this.toFixed(num, fixed)
    var deal = num.toString().split('.');
    let result = deal[0].toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    if (deal.length > 1) {
      return result + '.' + deal[1];
    } else {
      return result;
    }
  } else return "-";
}
function toFixed(num, fixed = 2) {
  // 先将数字转换为字符串
  let numStr = num.toString();
  // 检查是否有小数点
  let dotIndex = numStr.indexOf('.');
  // 如果没有小数点或者小数点后位数不超过fixed，直接返回原数
  if (dotIndex === -1 || (numStr.length - dotIndex - 1) <= fixed) {
      return numStr;
  }
  // 截取小数点后fixed位
  let truncatedStr = numStr.substring(0, dotIndex + fixed + 1);
  // // 如果截取后的小数点后位数不足fixed，补零
  // while ((truncatedStr.length - dotIndex - 1) < fixed) {
  //     truncatedStr += '0';
  // }
  // 返回处理后的字符串
  return Number(truncatedStr);
}

/**
 * 根据上传的文件大小对比尺寸大小是否符合内容
 * @param {url地址} file
 * @param {尺寸大小} size
 * @returns
 */
const isUpImgSize = (file, size, look = false) => {
  return new Promise((resolve) => {
    let url = ossUrl + file;
    let img = new Image();
    img.src = url;
    img.onload = () => {
      const num = size[0] * size[1];
      if (num == 0) {
        resolve(file);
        return;
      }
      if (`${size[0]}*${size[1]}` !== `${img.width}*${img.height}`) {
        if (look) {
          resolve("ok");
          msgWarning(
            `当前图片尺寸不符，请重新上传尺寸 ${size[0]}px * ${size[1]}px 图片`
          );
          return;
        }
        msgWarning(`图片尺寸不符，请传尺寸 ${size[0]}px * ${size[1]}px 图片`);
        return;
      }
      resolve(file);
    };
    img.onerror = () => {
      msgWarning(`图片获取失败，请重新上传`);
    };
  });
};

/** 
 * 判断是否是管理员权限
 * @param {用户登陆信息} user
 * 
 * @returns bool
*/
const isAdminRole = (user) => {
  const currentRole = user?.role;
  if (!user || !currentRole) {
    return false
  }
  // 管理员、超级管理员有所有权限
  if (currentRole == 'SUDOER') {
    return true
  }
  return false
}

/** 
 * 检查是否有权限， roleTags中只要有一个就可以
 * @param {权限} roleTags
 * @param {用户登陆信息} user
 * 
 * @returns bool 是否有权限
*/
const hasRole = (roleTags, user) => {
  const currentRole = user?.role;
  if (!user || !currentRole) {
    return false
  }
  roleTags = roleTags ?? [];
  if (roleTags.length == 0) {
    return true
  }
  // 管理员、超级管理员有所有权限
  if (isAdminRole(currentRole)) {
    return true
  }
  for (const i in roleTags) {
    const tag = roleTags[i];
    if (user.tags.indexOf(tag) != -1) {
      return true;
    }
  }
  return false;
}

// 是否拥有所有权限，roleTags 中的权限必须全部拥有
const hasAllRole = (roleTags, user) => {
  roleTags = roleTags ?? [];
  if (roleTags.length == 0) {
    return true
  }

  for (const i in roleTags) {
    const tag = roleTags[i];
    if (hasRole([tag], user) == false) {
      return false;
    }
  }
  return true;
}

/** 
 * 临时spa权限处理， 只有spa时，菜单只展示 spa，管理员展示全部
 * @param {权限} roleTags
 * @param {用户登陆信息} user
 * 
 * @returns bool 是否有权限
*/
const tempSPARole = (roleTags, user) => {
  const currentRole = user.role;
  roleTags = roleTags ?? [];
  // 管理员、超级管理员有所有权限
  if (isAdminRole(currentRole)) {
    return true
  }
  
  const tags = user.tags;
  const hasSpa = tags.indexOf('SPA_DATA') != -1;
  if (roleTags.indexOf('all') != -1) {
    return true
  } else if (roleTags.length == 0) {
    return hasSpa ? false : true
  } else if ((roleTags.indexOf('SPA_DATA') != -1) && hasSpa) {
    return true
  } else {
    return false
  }
}

// 是否以http开头
function isHttp(domain) {
  var patt = /^http?:\/\/.+/;
  if (!(patt.test(domain))) {
      return false;
  } else
  {
      return true;
  }
}

// 是否以https开头
function isHttps(domain) {
  var patt = /^https?:\/\/.+/;
  if (!(patt.test(domain))) {
      return false;
  } else
  {
      return true;
  }
}

// 店铺属性名称
const storePropertyName = (hasErpId, proerty) => {
  let name = '-'
  if (!hasErpId) {
    return name;
  }
  let propertyT = proerty ?? ''
  if (propertyT.length == 0) {
    name = '普通店'
  } else if (propertyT == 'weina') {
    name = '专卖店'
  } else if (propertyT == 'spa') {
    name = 'SPA店'
  }
  return name
}

// 店铺-营业状态名称
const storeBusinessStatusName = (hasErpId, status) => {
  let name
  if (!hasErpId) {
    return '-'
  }
  if (status == 'A') {
    name = '脱保'
  } else {
    name = '保护'
  }
  return name
}

// 处理店铺审核状态和颜色  status: 店铺状态
const storeAuditStatusName = (status) => {
  let name = '-'
  let color = ''
  if (status === 0) {
    name = '未审核'
    color = '#9E9E9E'
  } else if (status === 1) {
    name = '审核通过'
    color = '#006EFF'
  } else if (status === 2) {
    // if (isRegistSuccess) {
    //   name = '审核失败'
    // } else {
    //   name = '注册失败'
    // }
    name = '审核失败'
    color = '#F44336'
  } else if (status === 3) {
    name = '初审通过'
    color = '#0abf5b'
  } else if (status === 4) {
    name = '待初审'
    color = '#FF9800'
  }
  return {
    name, color
  }
}

// 该函数根据，start 和 end，返回一个包含从 start 到 end 的所有年份的字符串数组
const salesGetYears = (start, end) => {
  let years = [];
  for (let i = start; i <= end; i++) {
    years.push(i.toString());
  }
  return years;
}

// 该函数用于处理数据，根据给定的起始年份和结束年份 或 起始月份和结束月份，为每个数据项添加指定年份的销售额和同比增长率
const salesDealYearOrMonthData = (data, start, end, yearKey = 'yearsArray', valueKey = 'totalAmountsArray') => {
  if (_.isEmpty(start) || _.isEmpty(end)) {
    return data;
  }
  data.forEach((item) => {
    let values = []
    let years = item[yearKey].split(",");
    let totalAmounts = item[valueKey].split(",");
    let pre = 0
    let prePercent = ''  // 百分比
    let allFixed = 0          // 总和
    let allAmount = 0          // 总和
    for (let i = start; i <= end; i++) {
      let year = i.toString() // 年份
      let value = 0
      if (years.indexOf(year) != -1) {
        value = parseInt(totalAmounts[years.indexOf(year)])
      }
      allAmount += value
      allFixed += Number(toFixed(value / 10000, 0))
      if (year != start && pre != 0) {
        let preValue = toFixed(pre / 10000, 0)
        let curValue = toFixed(value / 10000, 0)
        prePercent = toFixed(((curValue - preValue) / preValue) * 100, 1) + "%"
      } else {
        prePercent = ''
      }
      values.push({
        year: year,
        value: value,
        pre: pre,
        prePercent: prePercent
      })
      item[i] = {
        value: value,
        pre: pre,
        prePercent: prePercent
      }
      pre = value
    }
    item.values = values;
    item.allFixed = allFixed;
    item.allAmount = allAmount;
  });
  return data
}

/**
 * 处理订单中第一次下单时间和最后一次下单时间
*/
const orderFirstAndLast = (list, key = 'orderDatas') => {
  for (let i = 0; i < list.length; i++) {
    let item = list[i]
    if (!_.isEmpty(item[key])) {
        let array = item[key].split(',')
        const sortedData = _.orderBy(array, item => new Date(item), ['asc']);
        item.first = _.head(sortedData)
        item.last = _.last(sortedData)
    } else {
        item.first = null
        item.last = null
    }
    // delete item.orderDatas
  }
  return list
}

/***
 *  校验是否是限量产品; return false: 无限量，true：限量
 * pSp: 产品的团队
 * tSp: 收货人的团队
 * */ 
const productIsLimit = (pSp, tSp) => {
  // 产品team
  let p = pSp ?? ''
  p = (p.length == 0) ? 'N' : p
  if (p == 'N') {
    return false
  }
  if (p == 'NG') {  // 当产品是 非徐州G 时
    return tSp == 'G' // 团队为 G 时限制
  } else {
    return p != tSp // 判断团队和产品是否相同，不同限制
  }
}

const tool = {
  promotion,
  promotionCheck,
  orderTabs,
  storeTabs,
  msg,
  msgSuccess,
  msgWarning,
  msgError,
  reset,
  url,
  ossUrl,
  lbsOssUrl,
  dataOssUrl,
  resource,
  debounce,
  throttle,
  disabledDateBefore,
  disabledDateAfter,
  toThousands,
  toFixed,
  isUpImgSize,
  spaTagType,
  raffleStatus,
  raffleStatusList,
  raffleStatusName,
  raffleStatusColor,
  isAdminRole,
  hasRole,
  hasAllRole,
  tempSPARole,
  isHttp,
  isHttps,
  storePropertyName,
  storeBusinessStatusName,
  storeAuditStatusName,
  salesGetYears,
  salesDealYearOrMonthData,
  orderFirstAndLast,
  productIsLimit
};

export default tool;
