<script setup>
import { ref, getCurrentInstance, reactive, onMounted } from "vue";
import editPasswordVue from "./components/editPassword.vue";
import msgNoticeVue from "./components/msgNotice.vue";

const { proxy } = getCurrentInstance();

const dataProps = reactive({
  orderMonth: '', // 订单月份
  trigger:['hover'], // 默认 hover 鼠标触发；  点击日期后 改为 click 点击触发
})

// 下拉的展示与否
const dropdownVisible = ref(false);

// 退出登录
const quitProject = () => {
  dropdownVisible.value = false;
  proxy.$api.loginOut().then(() => {
    cleanUserInfo();
  }).catch(() => {
    cleanUserInfo();
  });
};

const cleanUserInfo = () => {
  proxy.$piniaJs.login().clearData();
  proxy.$router.replace("/login");
}

// 个人信息
const userinfo = () => {
  dropdownVisible.value = false;
  proxy.$router.push("/userinfo");
};

// 修改密码
const refEditPsd = ref();
const editPassowrd = () => {
  dropdownVisible.value = false;
  refEditPsd.value.visible = true;
};
const editPassword = (result) => {
  const params = {
    currentPassword: result.password,
    newPassword: result.newPassword,
    confirmPassword: result.confirmPassword,
  };
  proxy.$api.editPassword(proxy.$piniaJs.login().jwt.user, params).then(() => {
    proxy.$tool.msgSuccess("修改成功");
    refEditPsd.value.visible = false;
  });
};

// 首页订单月份
const clickOrderMonth = (e) => {
  dropdownVisible.value = true;
  e.stopPropagation();
}

// 日历，弹出变化
const datePickerOpenChange = (status) => {
  dataProps.trigger = status ? ['click'] : ['hover']
}

const datePickerChange = (date) => {
  proxy.$api.systemOrderDate(date).then(() => {
    let user = proxy.$piniaJs.login().user
    user.orderMonth = date;
    dataProps.orderMonth = date
    proxy.$piniaJs.login().updateUser(user)
  });
}

// 用户信息
const userImg = ref();
const userName = ref()
const getUserInfo = () => {
  proxy.$api.getUserInfo(proxy.$piniaJs.login().jwt.user).then((res) => {
    userImg.value = res.data.image;
    userName.value = res.data.username
  });
};

onMounted(() => {
  dataProps.orderMonth = proxy.$piniaJs.login().user.orderMonth
  getUserInfo();
});

defineExpose({
  getUserInfo
})

// 通知
// const refMsgNotice = ref();
// const msgNotice = () => {
//   refMsgNotice.value.visible = true;
// };
</script>

<template>
  <!-- 消息通知 -->
  <msgNoticeVue ref="refMsgNotice"></msgNoticeVue>
  <!-- 修改密码 -->
  <editPasswordVue ref="refEditPsd" @confirm="editPassword"></editPasswordVue>
  <!-- 主体 -->
  <div class="layout flex">
    <img class="layout_logo cur" src="@/assets/images/homeView/logo.png" />
    <div class="layout_main flex">
      <!-- <img class="layout_main_msg cur" @click="msgNotice" src="@/assets/images/homeView/msg_none.png" />
        <img class="layout_main_msg cur" @click="msgNotice" src="@/assets/images/homeView/msg_tips.png" /> -->
      <div class="username">
        您好，{{ userName }}
      </div>
      <a-dropdown v-model:visible="dropdownVisible" :trigger="dataProps.trigger">
        <div class="layout_main_info cur">
          <img class="layout_main_info_img" :src="proxy.$tool.ossUrl + userImg" v-if="userImg" />
          <img v-else class="layout_main_info_img" src="@/assets/images/userImg.png" />
          <img class="layout_main_info_icon-down" src="@/assets/images/homeView/icon-down.png" />
        </div>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="userinfo">
              <a href="javascript:;">个人信息</a>
            </a-menu-item>
            <a-menu-item @click="editPassowrd">
              <a href="javascript:;">修改密码</a>
            </a-menu-item>
            <a-menu-item @click="clickOrderMonth">
              <a href="javascript:;">订单月份</a>
              <a-date-picker 
              :disabled="!proxy.$tool.hasAllRole(['SYSTEM'], proxy.$piniaJs.login().user)" 
              :bordered="false" 
              :allowClear="false"
              picker="month" 
              valueFormat="YYYY-MM"
              placeholder=""
              suffix-icon=""
              :value="dataProps.orderMonth"
              @openChange="datePickerOpenChange"
              @change="datePickerChange"
              class="date vn_whole_font"/>
            </a-menu-item>
            <a-menu-item @click="quitProject">
              <a href="javascript:;" style="color: #006eff">退出登录</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.date {
  width: 88px;
}
.limit_order_wx {
  margin-left: 18px; 
  margin-bottom: 3px;
}
.limit_order_back {
  margin-left: 30px; 
  margin-bottom: 3px;
}
.limit_org {
  margin-left: 6px; 
  margin-bottom: 3px;
}
.username {
  padding-right: 15px;
  color: white;
  font-weight: 400;
  font-size: 14px;
}
.layout {
  height: 100%;

  &_main {
    &_info {
      margin-right: 20px;

      &_icon-down {
        width: 9px;
        height: 6px;
        margin-left: 6px;
      }

      &_img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &_msg {
      width: 24px;
      height: 24px;
      margin-right: 35px;
    }
  }

  &_logo {
    width: 148px;
    height: 31px;
    margin-left: 26px;
  }
}
</style>
