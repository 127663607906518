<script setup>
import { ref, } from "vue";

const visible = ref(false);

defineExpose({
  visible,
});
</script>

<template>
  <div>
    <a-modal
      v-model:visible="visible"
      centered
      width="1200px"
      :footer="null"
      destroyOnClose
    >
      <div class="msgNotice">
        <p class="msgNotice_title">消息通知</p>
        <div class="overflow_y">
          <div class="msgNotice_main" v-for="item in 20" :key="item">
            <div class="msgNotice_main_header">
              <span class="title">系统推送</span>
              <span class="time">2020.10.10 12:00:00</span>
              <span class="status cur">已知晓</span>
            </div>
            <div class="msgNotice_main_content">
              您选购的商品已发货，如有任何问题请直接联系我们，请注意查看物流信息，欢迎您的再次选购，祝您生活愉快！
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 2px;
  height: 1px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #707070;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
  border-radius: 0;
  background: #ffffff;
}
.msgNotice {
  .overflow_y {
    overflow-y: auto;
    max-height: 600px;
    padding-right: 20px;
  }
  &_main {
    margin-top: 20px;
    padding-bottom: 21px;
    border-bottom: 1px solid #efefef;
    &_content {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
      margin-top: 14px;
    }
    &_header {
      .status {
        font-size: 12px;
        font-weight: 400;
        color: #006eff;
        float: right;
      }
      .time {
        font-size: 12px;
        font-weight: 400;
        color: #aaaaaa;
        margin-left: 28px;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
      }
    }
  }
  &_title {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
  }
}
</style>